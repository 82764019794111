(function () {
  'use strict';

  angular
  .module('neo.home.phases.phase.inscribedTeams')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.phases.phase.inscribedTeams', {
      url: '/inscribed-teams',
      templateUrl: 'home/phases/phase/inscribed-teams/inscribed-teams.tpl.html',
      controller: 'InscribedTeamsCtrl',
      controllerAs: 'vm',
      resolve: {
        categories: function (Categories) {
          return Categories.query().$promise;
        },
        genders: function (Genders) {
          return Genders.query().$promise;
        },
        sports: function (Sports) {
          return Sports.query().$promise;
        },
        towns: function (Poblacions) {
          return Poblacions.query().$promise;
        },
        InscribedTeams: function(InscribedTeams, $stateParams){
          return InscribedTeams.query({phaseId: $stateParams.phaseId}).$promise;
        }
      }
    })
    .state('home.phases.phase.inscribedTeamsNumber', {
      url: '/inscribed-teams-number',
      templateUrl: 'home/phases/phase/inscribed-teams/inscribed-teams.tpl.html',
      controller: 'InscribedTeamsNumberCtrl',
      controllerAs: 'vm',
      resolve: {
        categories: function (Categories) {
          return Categories.query().$promise;
        },
        genders: function (Genders) {
          return Genders.query().$promise;
        },
        sports: function (Sports) {
          return Sports.query().$promise;
        },
        inscribedTeamsNumber: function(InscribedTeamsNumber, $stateParams){
          return InscribedTeamsNumber.query({phaseId: $stateParams.phaseId}).$promise;
        }
      }
    })
  }

}());
